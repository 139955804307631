const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST'
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCESS'
const FETCH_DATA_FAIL = 'FETCH_DATA_FAIL'

const SET_SECTION = 'SET_SECTION'

const SET_PROJECT = 'SET_DATA'
const SET_PROJECT_SLIDE = 'SET_PROJECT_SLIDE'

export default {
  SET_SECTION,
  SET_PROJECT,
  FETCH_DATA_REQUEST,
  FETCH_DATA_FAIL,
  FETCH_DATA_SUCCESS,
  SET_PROJECT_SLIDE
}